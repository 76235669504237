<script>
import axios from "axios";
import Swal from "sweetalert2";
import CreateProcessTask from "@/views/pages/system-processes/modals/CreateProcessTask.vue";
import UpdateProcessTask from "@/views/pages/system-processes/modals/UpdateProcessTask.vue";
import DeleteProcessTask from "@/views/pages/system-processes/modals/DeleteProcessTask.vue";

import CreateProcess from "@/views/pages/system-processes/components/CreateProcess.vue";
import UpdateProcess from "@/views/pages/system-processes/modals/UpdateProcess.vue";
import DeleteProcess from "@/views/pages/system-processes/modals/DeleteProcess.vue";

import ReOrderProcesses from "@/views/pages/system-processes/modals/ReOrderProcesses.vue";

export default {
  name: "Processes",
  data() {
    return {
      title: "Processes",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Processes",
          active: true,
        },
      ],

      data: [],
      is_loading_data: false,
      is_initial_loading: false,
    };
  },
  computed: {
    available_departments() {
      return this.data.map(item => {
        return {
          id: item.id,
          name: item.name
        }
      })
    }
  },
  components: {
    CreateProcessTask,
    UpdateProcessTask,
    DeleteProcessTask,
    CreateProcess,
    UpdateProcess,
    DeleteProcess,
    ReOrderProcesses
  },
  methods: {
    async getProcesses(initial_loading = false) {
      this.is_loading_data = true
      this.is_initial_loading = initial_loading
      try {
        let response = await axios.get("/task/process_department/list/");
        this.data = response.data || []
        this.is_loading_data = false
        this.is_initial_loading = false
      } catch {
        this.is_loading_data = false
        this.is_initial_loading = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't load processes",
        });
      }
    },

    userManagesThisTask(user, task_users) {
      if (user && task_users) {
        let available_users_ids = task_users.map(item => item.user.id)
        return available_users_ids.includes(user.id)
      }
      return false
    },

    async assignThisTaskToUser(user, task) {
      let Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
      try {
        task.is_being_assigned_to_user = true
        await axios.post("/task/process_user/create/", {
          process_id: task.id,
          user_id: user.id
        });
        task.is_being_assigned_to_user = false
        await this.getProcesses()
        await Toast.fire({
          icon: "success",
          title: "Task assigned to user",
        });
      } catch {
        task.is_being_assigned_to_user = false
        await Toast.fire({
          icon: "error",
          title: "Couldn't assign task to user",
        });
      }
    },
    async unAssignThisTaskFromUser(task, user_id) {
      let Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
      try {
        let task_id = task.process_users.find(item => item.user.id === user_id).id
        task.is_being_unassigned_from_user = true
        await axios.delete(`/task/process_user/delete/${task_id}/`);
        task.is_being_unassigned_from_user = false
        await this.getProcesses()
        await Toast.fire({
          icon: "success",
          title: "Task unassigned from user",
        });
      } catch {
        task.is_being_unassigned_from_user = false
        await Toast.fire({
          icon: "error",
          title: "Couldn't unassign task from user",
        });
      }
    },
  },
  mounted() {
    this.getProcesses(true)
  }
}
</script>

<template>
  <CreateProcessTask
      @onTaskCreated="getProcesses()"
      ref="createProcessTaskModal"
  />

  <UpdateProcessTask
      @onTaskUpdated="getProcesses()"
      ref="updateProcessTaskModal"
  />

  <DeleteProcessTask
      @onTaskDeleted="getProcesses()"
      ref="deleteProcessTaskModal"
  />

  <UpdateProcess
      @onProcessUpdated="getProcesses()"
      ref="updateProcessModal"
  />

  <DeleteProcess
      @onProcessDeleted="getProcesses()"
      ref="deleteProcessModal"
  />

  <ReOrderProcesses
      @onProcessesReOrdered="getProcesses()"
      ref="reOrderProcessesModal"
  />

  <template v-if="!is_initial_loading">
    <div class="d-flex justify-content-between align-items-center gap-4">
      <ul class="nav nav-pills nav-customs nav-danger mb-3" role="tablist">
        <li v-for="(department, index) in data" :key="`department_${department.id}`" class="nav-item"
            role="presentation">
          <a class="nav-link" :class="{
        'active' : index === 0
      }"
             :href="`#department_processes_${department.id}`" target="_self"
             data-bs-toggle="tab" role="tab" aria-selected="true">
            {{ department.name }}
          </a>
        </li>
      </ul>
      <div>
        <CreateProcess
            :departments="available_departments"
            @onProcessCreated="getProcesses()"
            ref="createProcess"
        />
      </div>
    </div>


    <div class="tab-content text-muted">
      <div v-for="(department, index) in data" :key="`department_processes_${department.id}`"
           class="tab-pane"
           :class="{
        'active show' : index === 0
      }"
           :id="`department_processes_${department.id}`" role="tabpanel">
        <b-card no-body v-for="process in department.process_headers" :key="`process_${process.id}`">
          <b-card-body class="">
            <div class="table-responsive">
              <table class="table table-bordered table-nowrap mb-0">
                <thead>
                <tr class="bg-light">
                  <th scope="col">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <div>
                          <VTooltip>
                            <b-button variant="success" @click="this.$refs.createProcessTaskModal.openModal(process)"
                                      size="sm" class="waves-effect waves-light me-2">
                              <i class="mdi mdi-plus"></i>
                            </b-button>
                            <template #popper>
                              Add new task
                            </template>
                          </VTooltip>
                        </div>
                        <span>{{ process.title }}</span>
                      </div>
                      <div>
                        <div class="d-flex align-items-center gap-3"
                        >
                          <div class="cursor-pointer"
                               @click="this.$refs.reOrderProcessesModal.openModal(process)"
                          >
                            <i class="mdi mdi-order-bool-descending-variant align-middle fs-5 align-middle me-1"></i>
                            Reorder list
                          </div>
                          <i @click="this.$refs.updateProcessModal.openModal(process)"
                             v-b-tooltip.hover title="Edit task"
                             class="bx bx-edit-alt fs-4 align-middle p-2 cursor-pointer"></i>
                          <i @click="this.$refs.deleteProcessModal.openModal(process)"
                             v-b-tooltip.hover title="Delete task"
                             class="bx bx-trash-alt fs-4 align-middle p-2 cursor-pointer text-danger"></i>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th v-for="user in process.managers" :key="`user_${user.id}`"
                      scope="col" class="align-middle text-center">
                    {{ user.username }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(task, index) in process.processes" :key="`task_${task.id}`">
                  <td
                      @mouseover="task.is_hovered = true"
                      @mouseleave="task.is_hovered = false"
                  >
                    <div class="d-flex justify-content-between align-items-center gap-2 position-relative">
                      <div class="d-flex align-items-center gap-2">
                        <b>{{ index + 1 }}.</b>
                        {{ task.description }}
                      </div>
                      <Transition name="slide-fade">
                        <div class="d-flex gap-3 position-absolute top-0 end-0"
                             style="transform: translateY(-6px)"
                             v-if="task.is_hovered">
                          <i @click="this.$refs.updateProcessTaskModal.openModal(process, task)"
                             v-b-tooltip.hover title="Edit task"
                             class="bx bx-edit-alt fs-4 align-middle p-2 cursor-pointer"></i>
                          <i @click="this.$refs.deleteProcessTaskModal.openModal(process, task)"
                             v-b-tooltip.hover title="Delete task"
                             class="bx bx-trash-alt fs-4 align-middle p-2 cursor-pointer text-danger"></i>
                        </div>
                      </Transition>
                    </div>
                  </td>
                  <td v-b-tooltip.hover
                      :title="userManagesThisTask(user, task.process_users) ? user.username + ' ' + task.description : user.username + ' doesn\'t manage this task'"
                      :class="{
              'bg-soft-success border border-success' : userManagesThisTask(user, task.process_users),
            }"
                      v-for="user in process.managers" :key="`user_${user.id}`">

                    <div v-if="userManagesThisTask(user, task.process_users)"
                         @click="unAssignThisTaskFromUser(task, user.id)"
                         class="form-check form-switch form-switch-success text-center">
                      <input
                          :disabled="is_loading_data || task.is_being_unassigned_from_user || task.is_being_assigned_to_user"
                          class="form-check-input" type="checkbox" role="switch" checked>
                    </div>

                    <div v-else class="form-check form-switch form-switch-success text-center">
                      <input
                          :disabled="is_loading_data || task.is_being_assigned_to_user || task.is_being_unassigned_from_user"
                          @click="assignThisTaskToUser(user, task)"
                          class="form-check-input" type="checkbox" role="switch">
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-card-body>
        </b-card>
        <div v-if="department.process_headers && department.process_headers.length <= 0">
          No processes found for {{ department.name }}
        </div>
      </div>
    </div>
  </template>
  <b-spinner v-else></b-spinner>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
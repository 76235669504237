<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "UpdateProcess",
  emits: ['onProcessUpdated'],
  data() {
    return {
      showModal: false,
      process: {},
      is_updating_process: false,

      select_users: {
        options: [],
        mode: 'tags',
        searchable: true,
        placeholder: 'Search for users...',
        clearOnSelect: false
      },

      process_managers: []
    }
  },
  computed: {
    formIsValid() {
      return this.process.title ? this.process.title.trim().length > 0 && this.process_managers.length > 0 : false
    }
  },
  components: {
    Multiselect
  },
  methods: {
    openModal(process) {
      if (!process) return alert('Invalid process')
      this.process = process
      this.process_managers = []
      this.select_users.options = []
      setTimeout(() => {
        this.process_managers = this.process.managers.map(item => item.id)
        this.select_users.options = this.process.managers.map(item => {
          return {
            value: item.id,
            label: item.username,
          }
        })
      }, 200)
      this.showModal = true
    },
    async updateProcess() {
      if (!this.formIsValid) {
        await Swal.fire({
          icon: "error",
          title: "Please fill all fields",
        });
        return
      }
      this.is_updating_process = true
      try {
        await axios.post(`/task/process_header/update/${this.process.id}/`, {
          title: this.process.title.trim(),
          managers: this.process_managers.map(item => {
            return {
              id: item
            }
          })
        })
        this.is_updating_process = false
        this.showModal = false
        this.$emit('onProcessUpdated')
        await Swal.fire({
          icon: "success",
          title: "Process updated successfully",
        });
      } catch {
        this.is_updating_process = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't update process",
        });
      }
    },

    async asyncFindUsers(query) {
      if (query && query.trim().length <= 0) return;
      try {
        let response = await axios.get("/user/", {
          params: {
            username: query.trim(),
          },
        });
        let newOptions = response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });

        this.select_users.options = [...this.select_users.options, ...newOptions];

        this.select_users.options = this.select_users.options.reduce((unique, o) => {
          if (!unique.find(obj => obj.value === o.value && obj.label === o.label)) {
            unique.push(o);
          }
          return unique;
        }, []);
      } catch {
        alert("Error")
      }
    },
  },
}
</script>

<template>
  <b-modal v-model="showModal" hide-footer centered
           title="Edit Process Dialog" size="lg"
  >
    <form @submit.prevent="updateProcess()">
      <b-row class="align-items-end" gutter-y="4">
        <b-col xl="9">
          <div>
            <label class="form-label">Process title</label>
            <input v-model="process.title" required :disabled="is_updating_process"
                   class="form-control" placeholder="Enter task description">
          </div>
        </b-col>
        <b-col sm="12">
          <label class="form-label">Process Manager</label>
          <Multiselect
              v-model="process_managers"
              v-bind="select_users"
              @search-change="asyncFindUsers"
              class="ps-1" :clearOnSelect="false"
              :disabled="is_updating_process"
          />
        </b-col>
        <b-col xl="3" class="text-end">
          <b-button :disabled="is_updating_process || !formIsValid"
                    :type="is_updating_process ? 'button' : 'submit'" class="w-100" variant="success">
            Confirm & Save
            <i class="mdi mdi-arrow-right ms-1"></i>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-modal>
</template>

<style scoped>

</style>
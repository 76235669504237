<script>
import CreateProcessModal from "@/views/pages/system-processes/modals/CreateProcess.vue";

export default {
  name: "CreateProcessButton",
  emits: ['onProcessCreated'],
  components: {
    CreateProcessModal
  },
  props: {
    departments: {
      type: Array,
      required: true,
      default: () => []
    }
  },
}
</script>

<template>
  <div>
    <CreateProcessModal @onProcessCreated="this.$emit('onProcessCreated')"
                        ref="createProcessModal"/>
    <b-button @click="this.$refs.createProcessModal.openModal(departments || [])"
              variant="success" class="w-100 mb-3 btn-">
      Click to create a process
      <i class="ri-add-line align-middle me-2"></i>
    </b-button>
  </div>
</template>

<style scoped>

</style>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "CreateProcessTask",
  emits: ['onTaskCreated'],
  data() {
    return {
      showModal: false,
      process: {},
      is_creating_task: false,
      description: ''
    }
  },
  computed: {
    formIsValid() {
      return this.description.trim().length > 0
    }
  },
  methods: {
    openModal(process) {
      if (!process) return alert('Invalid process')
      this.process = process
      this.showModal = true
    },
    async createTask() {
      if (!this.formIsValid) {
        await Swal.fire({
          icon: "error",
          title: "Please fill all fields",
        });
        return
      }
      this.is_creating_task = true
      try {
        await axios.post(`/task/process/create/`, {
          header_id: this.process.id,
          descriptions: this.description.trim().split('\n').map(item => item.trim())
        })
        this.is_creating_task = false
        this.showModal = false
        this.$emit('onTaskCreated')
        await Swal.fire({
          icon: "success",
          title: "Task created successfully",
        });
      } catch {
        this.is_creating_task = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't create task",
        });
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" hide-footer centered
           :title="`New tasks - ${process.title}`" size="lg"
  >
    <form @submit.prevent="createTask()">
      <b-row class="align-items-end" gutter-y="3">
        <b-col xl="9">
          <div>
            <label class="form-label">
              Task description
              <span class="text-muted">(Each line represents a single task !)</span>
            </label>
            <textarea v-model="description" required :disabled="is_creating_task"
                      rows="10"
                      class="form-control" placeholder="Enter task descriptions"></textarea>
          </div>
        </b-col>
        <b-col xl="3" class="text-end">
          <b-button :disabled="is_creating_task || !formIsValid"
                    :type="is_creating_task ? 'button' : 'submit'" class="w-100" variant="success">
            Confirm & Create
            <i class="mdi mdi-arrow-right ms-1"></i>
          </b-button>
        </b-col>
        <b-col sm="12">
          <div class="table-responsive">
            <!-- Bordered Tables -->
            <table class="table table-bordered table-nowrap">
              <thead>
              <tr class="align-middle text-center">
                <th scope="col">#</th>
                <th scope="col">Title</th>
              </tr>
              </thead>
              <tbody v-if="this.description.trim().length > 0">
              <tr class="align-middle" v-for="(task, index) in this.description.trim().split('\n')"
                  :key="`task_${index}_${task}`">
                <th class="text-center" scope="row">{{ index + 1 }}</th>
                <td>{{ task }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </form>
  </b-modal>
</template>

<style scoped>

</style>
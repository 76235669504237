<script>
import PageHeader from "@/components/page-header.vue";
import ProcessList from "@/views/pages/system-processes/components/ProcessList.vue";

export default {
  name: "Processes",
  components: {
    PageHeader,
    ProcessList
  },
  data() {
    return {
      title: "Processes",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Processes",
          active: true,
        },
      ],
    };
  },
}
</script>

<template>
  <PageHeader :title="title" :items="items"/>
  <ProcessList/>
</template>

<style scoped>

</style>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default {
  name: "CreateProcessModal",
  emits: ['onProcessCreated'],
  data() {
    return {
      showModal: false,
      is_creating_process: false,
      process: {
        title: '',
        managers_id: [],
        department_id: null,
      },

      select_users: {
        options: [],
        mode: 'tags',
        searchable: true,
        placeholder: 'Search for users...',
        clearOnSelect: false
      },

      select_department: {
        options: [],
        searchable: true,
        placeholder: 'Select department',
        clearOnSelect: false
      },
    }
  },
  computed: {
    formIsValid() {
      return this.process.title.trim().length > 0 && this.process.managers_id.length > 0 && this.process.department_id
    }
  },
  components: {
    Multiselect
  },
  methods: {
    openModal(departments) {
      if (!departments) return alert('Invalid departments')
      this.select_department.options = departments.map(item => {
        return {
          value: item.id,
          label: item.name
        }
      })
      this.showModal = true
    },
    async createProcess() {
      if (!this.formIsValid) {
        await Swal.fire({
          icon: "error",
          title: "Please fill all fields",
        });
        return
      }
      this.is_creating_process = true
      try {
        await axios.post(`/task/process_header/create/`, {
          title: this.process.title.trim(),
          managers: this.process.managers_id.map(item => {
            return {
              id: item
            }
          }),
          department_id: this.process.department_id
        })
        this.is_creating_process = false
        this.showModal = false
        this.$emit('onProcessCreated')
        await Swal.fire({
          icon: "success",
          title: "Process created successfully",
        });
      } catch {
        this.is_creating_process = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't create process",
        });
      }
    },

    async loadSomeUsers() {
      try {
        let response = await axios.get("/user/", {
          params: {
            company: 'interrail',
          },
        });
        this.select_users.options = response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });
      } catch {
        alert("Error")
      }
    },
    async asyncFindUsers(query) {
      if (query && query.trim().length <= 0) return;
      try {
        let response = await axios.get("/user/", {
          params: {
            username: query.trim(),
          },
        });
        let newOptions = response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });

        this.select_users.options = [...this.select_users.options, ...newOptions];

        this.select_users.options = this.select_users.options.reduce((unique, o) => {
          if (!unique.find(obj => obj.value === o.value && obj.label === o.label)) {
            unique.push(o);
          }
          return unique;
        }, []);
      } catch {
        alert("Error")
      }
    },
  },
  mounted() {
    this.loadSomeUsers()
  },
}
</script>

<template>
  <b-modal v-model="showModal" hide-footer centered
           title="Create a new process" size="lg"
  >
    <form @submit.prevent="createProcess()">
      <b-row class="align-items-end" gutter-y="3">
        <b-col sm="12">
          <div>
            <label class="form-label">Department</label>
            <Multiselect
                v-model="process.department_id"
                v-bind="select_department"
                class="ps-1" :clearOnSelect="false"
                :disabled="is_creating_process"
            />
          </div>
        </b-col>
        <b-col sm="12">
          <div>
            <label class="form-label">Process title</label>
            <input v-model="process.title" required :disabled="is_creating_process"
                   class="form-control" placeholder="Enter process title">
          </div>
        </b-col>
        <b-col sm="12">
          <div>
            <label class="form-label">Assign to</label>
            <Multiselect
                v-model="process.managers_id"
                v-bind="select_users"
                @search-change="asyncFindUsers"
                class="ps-1" :clearOnSelect="false"
                :disabled="is_creating_process"
            />
          </div>
        </b-col>
        <b-col sm="12" class="text-end">
          <b-button :disabled="is_creating_process || !formIsValid"
                    :type="is_creating_process ? 'button' : 'submit'" class="w-100" variant="success">
            Confirm & Create
            <i class="mdi mdi-arrow-right ms-1"></i>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-modal>
</template>

<style scoped>

</style>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "UpdateProcessTask",
  emits: ['onTaskUpdated'],
  data() {
    return {
      showModal: false,
      process: {},
      task: {},
      is_updating_task: false,
    }
  },
  computed: {
    formIsValid() {
      return this.task.description ? this.task.description.trim().length > 0 : false
    }
  },
  methods: {
    openModal(process, task) {
      if (!process && task) return alert('Invalid process or task')
      this.process = process
      this.task = task
      this.showModal = true
    },
    async updateTask() {
      if (!this.formIsValid) {
        await Swal.fire({
          icon: "error",
          title: "Please fill all fields",
        });
        return
      }
      this.is_updating_task = true
      try {
        await axios.post(`/task/process/update/${this.task.id}/`, {
          description: this.task.description.trim(),
        })
        this.is_updating_task = false
        this.showModal = false
        this.$emit('onTaskUpdated')
        await Swal.fire({
          icon: "success",
          title: "Task updated successfully",
        });
      } catch {
        this.is_updating_task = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't update task",
        });
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" hide-footer centered
           :title="process.title" size="lg"
  >
    <form @submit.prevent="updateTask()">
      <b-row class="align-items-end" gutter-y="3">
        <b-col xl="9">
          <div>
            <label class="form-label">Task description</label>
            <textarea v-model="task.description" required :disabled="is_updating_task" rows="5"
                      class="form-control" placeholder="Enter task description"></textarea>
          </div>
        </b-col>
        <b-col xl="3" class="text-end">
          <b-button :disabled="is_updating_task || !formIsValid"
                    :type="is_updating_task ? 'button' : 'submit'" class="w-100" variant="success">
            Confirm & Save
            <i class="mdi mdi-arrow-right ms-1"></i>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-modal>
</template>

<style scoped>

</style>
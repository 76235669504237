<script>
import {VueDraggableNext} from 'vue-draggable-next';
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ReOrderProcesses",
  emits: ['onProcessesReOrdered'],
  data() {
    return {
      process: {},
      showModal: false,
      is_saving: false
    }
  },
  components: {
    draggable: VueDraggableNext,
  },
  methods: {
    openModal(process) {
      if (!process) return alert("Invalid process")
      this.process = {...process}
      this.showModal = true
    },
    async onDragEnd() {
      let Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        showCloseButton: true,
      });
      this.is_saving = true
      try {
        await axios.put(`/task/process_update_position/${this.process.id}/`, {
          positions: this.process.processes.map(item => item.id)
        })
        this.is_saving = false
        await Toast.fire({
          icon: "success",
          title: "List reordered successfully",
        });
        this.$emit('onProcessesReOrdered')
        this.showModal = false
      } catch {
        this.is_saving = false
        await Toast.fire({
          icon: "error",
          title: "Couldn't reorder list",
        });
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="ReOrder Modal" centered hide-footer>

    <p v-if="is_saving">
      <i class="mdi mdi-loading mdi-spin fs-5 text-success me-2 align-middle"></i>
      Saving...
    </p>
    <b-list-group>
      <draggable @end="onDragEnd"
                 :list="process.processes || []" class="dragArea">
        <BListGroupItem v-for="(process_item, index) in process.processes" :key="`process_${process_item.id}`"
                        tag="label">
          <div class="d-flex gap-2">
            <label class="form-check-label" :for="`check_task_order_${process_item.id}`">
              {{ index + 1 }}.
            </label>
            <span>{{ process_item.description }}</span>
          </div>
        </BListGroupItem>
      </draggable>
    </b-list-group>
  </b-modal>
</template>

<style scoped>

</style>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "DeleteProcessTask",
  emits: ['onTaskDeleted'],
  data() {
    return {
      showModal: false,
      process: {},
      task: {},
      is_deleting_task: false,
    }
  },
  methods: {
    openModal(process, task) {
      if (!process && task) return alert('Invalid process or task')
      this.process = process
      this.task = task
      this.showModal = true
    },
    async updateTask() {
      this.is_deleting_task = true
      try {
        await axios.delete(`/task/process/delete/${this.task.id}/`)
        this.is_deleting_task = false
        this.showModal = false
        this.$emit('onTaskDeleted')
        await Swal.fire({
          icon: "success",
          title: "Task deleted successfully",
        });
      } catch {
        this.is_deleting_task = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't deleted task",
        });
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" hide-footer centered
           :title="process.title" size="lg"
  >
    <form @submit.prevent="updateTask()">

      <b-alert show dismissible variant="danger" class="alert-border-left fade show mb-4" role="alert">
        <i class="ri-error-warning-line me-3 align-middle"></i> <strong>Warning</strong> - You're about to delete this task.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </b-alert>


      <b-row class="align-items-end" gutter-y="3">
        <b-col xl="9">
          <div>
            <label class="form-label">Task description</label>
            <input :value="task.description" disabled
                   class="form-control" placeholder="Enter task description">
          </div>
        </b-col>
        <b-col xl="3" class="text-end">
          <b-button :disabled="is_deleting_task"
                    :type="is_deleting_task ? 'button' : 'submit'" class="w-100" variant="danger">
            Confirm & Delete
            <i class="bx bx-trash-alt ms-1"></i>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-modal>
</template>

<style scoped>

</style>